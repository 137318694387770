<template>
  <el-form ref="form" :model="filters" class="filters">
    <el-row :gutter="12">
      <el-col :span="12">
        <base-input
          class="search"
          ref="search"
          prop="search"
          v-model.trim="filters.userSearch"
          name="search"
          :label="$t('backoffice.plugins.user_search')"
          auto-complete="off"
          :placeholder="$t('dashboard.search_bar.placeholder')"
        >
          <template #prependInput>
            <i class="ri-search-line"></i>
          </template>
        </base-input>
      </el-col>
      <el-col :span="6">
        <base-datepicker
          v-model="filters.purchaseRequestedFrom"
          name="purchaseRequestedFrom"
          :clearable="true"
          valueFormat="yyyy-MM-ddTHH:mm:ssZZ"
          :label="$t('backoffice.plugins.requested_on_from')"
          :max-width="200"
          :picker-options="purchaseRequestedFromDatePickerOptions"
        />
      </el-col>

      <el-col :span="6">
        <base-datepicker
          v-model="filters.purchaseRequestedTo"
          name="purchaseRequestedTo"
          :clearable="true"
          valueFormat="yyyy-MM-ddTHH:mm:ssZZ"
          :label="$t('backoffice.plugins.requested_on_to')"
          :max-width="200"
          :picker-options="purchaseRequestedToDatePickerOptions"
        />
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { DateTime } from 'luxon'
import { debounce } from 'lodash'
export default {
  name: 'OrderFilters',
  props: {
    value: {
      default: {
        userSearch: undefined,
        purchaseRequestedTo: undefined,
        purchaseRequestedFrom: undefined
      }
    }
  },
  data () {
    return {
      filters: {
        userSearch: undefined,
        purchaseRequestedTo: undefined,
        purchaseRequestedFrom: undefined
      }
    }
  },
  computed: {
    purchaseRequestedFromDatePickerOptions () {
      return {
        disabledDate: (value) => {
          if (this.filters.purchaseRequestedTo) {
            return (
              value >
                DateTime.fromSQL(this.filters.purchaseRequestedTo)
                  .startOf('day')
                  .toJSDate() || value > DateTime.local().startOf('day')
            )
          }
          return value > DateTime.local().startOf('day')
        }
      }
    },
    purchaseRequestedToDatePickerOptions () {
      return {
        disabledDate: (value) => {
          if (this.filters.purchaseRequestedFrom) {
            return (
              value <
                DateTime.fromSQL(this.filters.purchaseRequestedFrom)
                  .startOf('day')
                  .toJSDate() || value > DateTime.local().startOf('day')
            )
          }
          return value > DateTime.local().startOf('day')
        }
      }
    }
  },
  watch: {
    'filters.userSearch': {
      handler: debounce(function () {
        this.updateFilters()
      }, 700),
      deep: true
    },
    'filters.purchaseRequestedTo': debounce(function () {
      this.updateFilters()
    }, 200),
    'filters.purchaseRequestedFrom': debounce(function () {
      this.updateFilters()
    }, 200)
  },
  methods: {
    handleSubmit () {
      this.$emit('submit')
    },
    updateFilters () {
      this.$emit('input', {
        ...this.filters,
        userSearch: this.filters?.userSearch || undefined
      })
    }
  },
  mounted () {
    this.filters = { ...this.value }
  }
}
</script>

<style scoped>
.filters {
  margin-top: 1rem;
}
.search :deep(.el-form-item__label) {
  margin-bottom: 5px;
}
</style>
