import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getByFilter: (filter, options) => api(options).post(`${PORTAL_SERVICE_V1}/invoice/findByFilter`, filter),
  downloadByFilterPdf: (filter, options) => api({
    ...options,
    responseType: 'arraybuffer',
    dataOnly: false,
    headers: {
      Accept: '*/*'
    }
  }).post(`${PORTAL_SERVICE_V1}/invoice/downloadByFilter/pdf`, filter),

  downloadByFilterXml: (filter, options) => api({
    ...options,
    responseType: 'arraybuffer',
    dataOnly: false,
    headers: {
      Accept: '*/*'
    }
  }).post(`${PORTAL_SERVICE_V1}/invoice/downloadByFilter/xml`, filter),

  downloadByFilterCsv: (filter, options) => api({
    ...options,
    responseType: 'arraybuffer',
    dataOnly: false,
    headers: {
      Accept: '*/*'
    }
  }).post(`${PORTAL_SERVICE_V1}/invoice/downloadByFilter/csv`, filter),

  downloadInvoicePdf: (ospId, options) => api({
    ...options,
    responseType: 'arraybuffer',
    dataOnly: false,
    headers: {
      Accept: '*/*'
    }
  }).get(`${PORTAL_SERVICE_V1}/invoice/download/pdf/${ospId}`),

  downloadInvoiceXml: (ospId, options) => api({
    ...options,
    responseType: 'arraybuffer',
    dataOnly: false,
    headers: {
      Accept: '*/*'
    }
  }).get(`${PORTAL_SERVICE_V1}/invoice/download/xml/${ospId}`),

  downloadInvoiceDetailCsv: (ospId, options) => api({
    ...options,
    responseType: 'arraybuffer,application/json',
    dataOnly: false,
    headers: {
      Accept: '*/*'
    }
  }).get(`${PORTAL_SERVICE_V1}/invoice/download/detail/csv/${ospId}`),

  pay: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/invoice/pay`, form),

  downloadInvoiceXlsx: (form, options) => api({
    ...options,
    responseType: 'arraybuffer',
    dataOnly: false,
    headers: {
      Accept: '*/*'
    }
  }).post(`${PORTAL_SERVICE_V1}/invoice/download/xlsx`, form)

}
