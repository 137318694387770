import api from '@/api'
import Repository from './Repository'
import Invoice from '../models/Invoice'
import { DateTime } from 'luxon'
import { uploadFile } from '@/app/helpers'

class InvoiceRepository extends Repository {
  constructor () {
    super(Invoice)
  }

  getByFilter (filter, options = {}) {
    const optionsData = this.addWrapperWithPaginator(options)
    const filterData = this.prepareFilter(filter)
    return api.invoices.getByFilter(filterData, optionsData)
  }

  downloadAllXml (filter, options = {}) {
    const filterData = this.prepareFilter(filter)
    return api.invoices.downloadByFilterXml(filterData, {
      ...options,
      onSuccess: response => {
        this.uploadFile(response)
        options.onSuccess && options.onSuccess(response)
      }
    })
  }

  downloadAllPdf (filter, options = {}) {
    const filterData = this.prepareFilter(filter)
    return api.invoices.downloadByFilterPdf(filterData, {
      ...options,
      onSuccess: response => {
        this.uploadFile(response)
        options.onSuccess && options.onSuccess(response)
      }
    })
  }

  downloadAllCsv (filter, options = {}) {
    const filterData = this.prepareFilter(filter)
    return api.invoices.downloadByFilterCsv(filterData, {
      ...options,
      onSuccess: response => {
        this.uploadFile(response)
        options.onSuccess && options.onSuccess(response)
      }
    })
  }

  downloadInvoiceXml (ospId, options = {}) {
    return api.invoices.downloadInvoiceXml(ospId, {
      ...options,
      onSuccess: response => {
        this.uploadFile(response)
        options.onSuccess && options.onSuccess(response)
      }
    })
  }

  downloadInvoicePdf (ospId, options = {}) {
    return api.invoices.downloadInvoicePdf(ospId, {
      ...options,
      onSuccess: response => {
        this.uploadFile(response)
        options.onSuccess && options.onSuccess(response)
      }
    })
  }

  downloadInvoiceCsv (ospId, options = {}) {
    return api.invoices.downloadInvoiceDetailCsv(ospId, {
      ignoreErrors: ['NOT_FOUND'],
      ...options,
      onSuccess: response => {
        this.uploadFile(response)
        options.onSuccess && options.onSuccess(response)
      }
    })
  }

  downloadInvoiceXlsx (form, options = {}) {
    return api.invoices.downloadInvoiceXlsx(form, {
      ...options,
      onSuccess: response => {
        this.uploadFile(response, 'xlsx')
        options.onSuccess && options.onSuccess(response)
      }
    })
  }

  uploadFile (response, extension) {
    let filename = 'invoice'
    const contentDisposition = response?.headers?.['content-disposition']
    const fallbackMatch = contentDisposition.match(/filename=([^;]+)/)
    if (fallbackMatch && fallbackMatch[1]) {
      filename = fallbackMatch[1]
    }
    uploadFile(response, filename, extension)
  }

  prepareFilter (filter) {
    return {
      ...filter,
      invoiceMonthFrom: DateTime.fromISO(filter.invoiceMonthFrom).toFormat('yyyyMM'),
      invoiceMonthTo: DateTime.fromISO(filter.invoiceMonthTo).toFormat('yyyyMM')
    }
  }
}

export default InvoiceRepository
