import { api, PORTAL_SERVICE_V1 } from '../../app/services/ApiService'

export default {
  getOpen: (form = {}, options) => api(options).post(`${PORTAL_SERVICE_V1}/order/getOpen`, form),
  getHandled: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/order/getHandled`, form),
  confirm: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/order/confirm`, form),
  cancel: (form, options) => api(options).post(`${PORTAL_SERVICE_V1}/order/cancel`, form),
  getConfig: (corporateAccountOspId, options) =>
    api(options).post(`${PORTAL_SERVICE_V1}/order/config/get`, { corporateAccountOspId }),
  updateConfig: (data, options) => api(options).post(`${PORTAL_SERVICE_V1}/order/config/update`, data),
  getDetails: (id, options) => api(options).get(`${PORTAL_SERVICE_V1}/order/getDetails/${id}`)
}
