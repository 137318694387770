<template>
  <div class="backoffice_general-orders">
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('backoffice.orders.general_orders.alert')"></span>
    </el-alert>
    <!-- <OrderFilters v-model="filters" /> -->

    <el-table
      id="ordersTable"
      ref="table"
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      :default-sort="defaultSort"
      row-class-name="table-row"
      @sort-change="sortChange"
      style="width: 100%"
    >
      <el-table-column prop="icon" width="37">
        <template>
          <i class="ri-contacts-line"></i>
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="userName"
        :label="$t('general.label.user')"
        min-width="180"
        :show-overflow-tooltip="true"
        sortable="sortColumn"
      >
        <template slot-scope="{ row }">
          <a href="#" @click.prevent="showUserDetails(row)">{{ row.userName }}</a>
        </template>
      </el-table-column>
      <el-table-column
        prop="service"
        :label="$t('general.label.service')"
        :show-overflow-tooltip="true"
        min-width="110"
        sortable="sortColumn"
      >
      </el-table-column>
      <el-table-column
        prop="action"
        :label="$t('general.label.action')"
        min-width="170"
        :show-overflow-tooltip="true"
        sortable="sortColumn"
      >
      </el-table-column>
      <el-table-column
        class-name="actions-cell justify-between"
        prop="mobibCardNumber"
        :label="$t('general.label.card_number')"
        :show-overflow-tooltip="true"
        width="220"
        sortable="sortColumn"
      >
        <template slot-scope="{ row }">
          <template v-if="row.mobibCardNumber || row.cardNumber">
            <span style="margin-right: 10px">{{ selectCardNumber(row) }}</span>
            <base-button
              @click.stop="handleCopy(selectCardNumber(row))"
              size="small"
              prepend-icon="ri-file-copy-line"
            ></base-button>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="requestedOnDate"
        :label="$t('general.label.requested_on')"
        :show-overflow-tooltip="true"
        min-width="110"
        sortable="sortColumn"
      >
      </el-table-column>
      <el-table-column class-name="actions-cell" prop="buttons" :label="''" width="150">
        <template slot-scope="{ row }">
          <base-button
            :label="$t('general.confirm')"
            type="primary"
            size="small"
            @click.stop="handleConfirm(row)"
          ></base-button>
          <base-button
            @click.stop="handleDelete(row)"
            size="small"
            prepend-icon="ri-delete-bin-2-line"
          ></base-button>
        </template>
      </el-table-column>
      <el-table-column
        class-name="text-bold"
        prop="corporateAccountExtId"
        :label="$t('general.label.ca')"
        :show-overflow-tooltip="true"
        min-width="150"
        sortable="sortColumn"
      >
      </el-table-column>
      <el-table-column
        prop="partnerExtId"
        :label="$t('general.label.partner')"
        :show-overflow-tooltip="true"
        min-width="150"
        sortable="sortColumn"
      >
      </el-table-column>
    </el-table>
    <general-order-confirmation
      ref="orderConfirmation"
      @confirm="confirm"
    ></general-order-confirmation>
    <general-order-cancel
      ref="orderCancel"
      @confirm="confirmCancel"
    ></general-order-cancel>
    <process-nmbs-order
      ref="processNmbsOrder"
      @confirm="handleSeasonTicketOrder"
    ></process-nmbs-order>
    <process-de-lijn-order
      ref="processDeLijnOrder"
      @confirm="handleSeasonTicketOrder"
    ></process-de-lijn-order>
    <process-mivb-order
      ref="processMivbOrder"
      @confirm="handleSeasonTicketOrder"
    ></process-mivb-order>
    <cancel-order
      ref="cancelSeasonTicketOrder"
      @confirm="handleSeasonTicketOrder"
    ></cancel-order>
  </div>
</template>

<script>
import { orderBy } from 'lodash'
import OrderRepository from '@/app/repositories/OrderRepository'
import { PROVIDERS } from '@/app/helpers'
import TableLoader from '@/mixins/TableLoader'
import GeneralOrderConfirmation from '@/components/Backoffice/Orders/GeneralOrderConfirmation'
import GeneralOrderCancel from '@/components/Backoffice/Orders/GeneralOrderCancel'
import ProcessDeLijnOrder from '@/components/OrderHandling/SeasonCardOrders/ProcessDeLijnOrder.vue'
import ProcessNmbsOrder from '@/components/OrderHandling/SeasonCardOrders/ProcessNmbsOrder.vue'
import ProcessMivbOrder from '@/components/OrderHandling/SeasonCardOrders/ProcessMivbOrder'
import CancelOrder from '@/components/OrderHandling/SeasonCardOrders/CancelOrder'
// import OrderFilters from '@/components/Backoffice//Orders/OrderFilters'

const defaultSort = { order: 'ascending', prop: 'userName' }

const orderRepository = new OrderRepository()
export default {
  name: 'GeneralOrders',
  components: {
    GeneralOrderConfirmation,
    GeneralOrderCancel,
    ProcessDeLijnOrder,
    ProcessNmbsOrder,
    CancelOrder,
    ProcessMivbOrder
    // OrderFilters
  },
  mixins: [TableLoader],
  props: {
    totalPages: {
      type: Number,
      default: 0
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 0,
          size: 25,
          sort: {}
        }
      }
    }
  },
  data () {
    return {
      defaultSort,
      orders: [],
      filters: {
        userSearch: '',
        purchaseRequestedTo: undefined,
        purchaseRequestedFrom: undefined
      }
    }
  },
  computed: {
    sortedOrders () {
      const prop = this.pagination.sort.prop
      const order = this.pagination.sort.order === 'descending' ? 'desc' : 'asc'
      return orderBy(
        this.orders,
        (v) => {
          try {
            return v[prop].toLowerCase()
          } catch (e) {
            return v[prop]
          }
        },
        order
      )
    },
    tableData () {
      const startItem = this.pagination.size * this.pagination.page
      const endItem = this.pagination.size * (this.pagination.page + 1)
      return this.sortedOrders.slice(startItem, endItem)
    }
  },
  // watch: {
  //   filters: {
  //     handler: function () {
  //       this.fetchData()
  //     },
  //     deep: true
  //   }
  // },
  methods: {
    confirm (form) {
      const loading = this.TableLoader_show()
      return orderRepository.confirm(form, {
        successNotification: this.$t(
          'backoffice.orders.general_orders.confirmation.success_notification'
        ),
        onSuccess: () => {
          this.fetchData()
        },
        onFinally: () => {
          loading.close()
        }
      })
    },
    confirmCancel (form) {
      const loading = this.TableLoader_show()
      return orderRepository.cancel(form, {
        successNotification: this.$t(
          'backoffice.orders.general_orders.cancel.success_notification'
        ),
        onSuccess: () => {
          this.fetchData()
        },
        onFinally: () => {
          loading.close()
        }
      })
    },
    fetchData () {
      const loading = this.TableLoader_show()
      return orderRepository.getOpen(
        {},
        {
          onSuccess: (orders) => {
            this.orders = orders
            const totalPages = Math.ceil(this.orders.length / this.pagination.size)
            this.$emit('update:totalPages', totalPages)
          },
          onFinally: () => {
            loading.close()
          }
        }
      )
    },
    handleConfirm (order) {
      if (['PURCHASE_SEASON_TICKET', 'RENEW_SEASON_TICKET'].includes(order.action)) {
        switch (order.mrpOspId) {
          case PROVIDERS.NMBS:
            return this.$refs.processNmbsOrder.show(order)
          case PROVIDERS.DELIJN:
            return this.$refs.processDeLijnOrder.show(order)
          case PROVIDERS.MIVB:
            return this.$refs.processMivbOrder.show(order)
        }
      }
      this.$refs.orderConfirmation.show(order)
    },
    handleDelete (order) {
      if (order.action === 'PURCHASE_SEASON_TICKET') {
        return this.$refs.cancelSeasonTicketOrder.show(order)
      }
      this.$refs.orderCancel.show(order)
    },
    handleCopy (message) {
      this.$copyText(message).then((e) => {
        this.$notify({
          title: this.$t('backoffice.orders.general_orders.copy_success.title'),
          message: this.$t('backoffice.orders.general_orders.copy_success.text'),
          type: 'success'
        })
        console.log(e)
      })
    },
    showUserDetails (order) {
      const loading = this.TableLoader_show()
      this.$router.push(
        {
          name: 'AppUserDetailView',
          params: {
            userOspId: order.userOspId
          },
          query: {
            corporateAccountOspId: order.corporateAccountOspId
          }
        },
        () => this.$sleep(50).then(loading.close),
        () => this.$sleep(50).then(loading.close)
      )
    },
    sortChange (sort) {
      this.pagination.sort = sort
    },
    selectCardNumber (row) {
      return row.action === 'PURCHASE_CHARGING_CARD'
        ? row.cardNumber
        : row.mobibCardNumber
    },
    async handleSeasonTicketOrder ({ form, event }) {
      const successNotification =
        event === 'confirmOrder'
          ? this.$t('order_handling.season_card_orders.confirmed_message')
          : this.$t('order_handling.season_card_orders.cancelled_message')
      const loading = this.TableLoader_show()
      const options = {
        successNotification,
        onSuccess: () => {
          this.fetchData()
        },
        onFinally: () => {
          loading.close()
        }
      }
      try {
        await this.$api.seasonTicketOrder[event](form, options)
      } catch (e) {
        console.error(e)
      }
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style scoped></style>
